@import "primereact/resources/themes/saga-orange/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.css";

@tailwind base;
@tailwind components;
@tailwind utilities;
@config "../tailwind.config.js";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body .MuiPaper-root {
  background: #0F0F0F;
}

.p-card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.p-card-title {
  flex: 1;
}
